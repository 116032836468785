<template>
    <div class="login-box">
        <div class="login-logo">
            <a href="">
                 <img src="/assets/dist/img/nakpro-logo1.png" style="max-width: 200px;">
                <h3 style="color: #FFFFFF">Hesap Oluştur</h3>
            </a>
        </div>
        <div class="login-box-body">
            <p class="login-box-msg">Aşağıdaki formu doldurarak yeni hesap oluşturabilirsiniz.</p>

            <form action="" method="post">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group has-feedback">
                            <input required="" type="email" class="form-control" placeholder="Ad" style="background-color: #13263c; border: solid 1px #1a304a; color: #FFFFFF; border-radius: 5px;">
                            <span class="glyphicon glyphicon-user form-control-feedback"></span>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group has-feedback">
                            <input required="" type="password" class="form-control" placeholder="Soyad" style="background-color: #13263c; border: solid 1px #1a304a; color: #FFFFFF; border-radius: 5px;">
                            <span class="glyphicon glyphicon-user form-control-feedback"></span>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group has-feedback">
                            <input required="" type="password" class="form-control" placeholder="GSM" style="background-color: #13263c; border: solid 1px #1a304a; color: #FFFFFF; border-radius: 5px;">
                            <span class="glyphicon glyphicon-phone form-control-feedback"></span>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group has-feedback">
                            <input required="" type="password" class="form-control" placeholder="Şifre" style="background-color: #13263c; border: solid 1px #1a304a; color: #FFFFFF; border-radius: 5px;">
                            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group has-feedback">
                            <button type="submit" class=" btn-block" style="padding: 7px 0px; border-radius: 3px; background-color: #13263c; border: solid 1px #13263c;">HESAP OLUŞTUR</button>
                        </div>
                    </div>

                    <div class="col-md-12 text-center">
                        <router-link to="/">Giriş yapmak için buraya tıklayın.</router-link><br>
                        <router-link to="/firma/sifre-yenile">Şifrenizi unuttuysanız buraya tıklayın.</router-link>
                    </div>

                    <div class="col-md-12"></div>               
                </div>
            </form>         
        </div>
        
        <div class="row">
            <div class="col-md-12 text-center">
                <div style="margin-top: 15px; font-size: 13px; color: #EEEEEE; font-size: 13px;">
                    Nakpro v.1.0<br>
                    Her Hakkı Mahfuzdur | &copy; 2022<br>
                    <a target="_blank" style="color: #FFFFFF; font-weight: 500; font-size: 13px;" href="https://nakpro.web.tr">www.nakpro.web.tr</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";


export default {
  data (){
    return {
      text: '',
      list: ['test','sdfsdf','dfsdfsdf']
    }
  },
  methods: {
    add() {
      axios.post('http://127.0.0.1:8080', {"name": "asaf"})
      .then( (response) => {
        console.log(response);
      })
      .catch( (response) => {
        console.log(response);
      });
    }
  }
};

</script>

<style>
</style>

